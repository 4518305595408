<template>
  <div class="row">
    <div class="col-md-12">
      <div class="panel">
        <div class="panel-heading bg-dark text-light">
          <h3 class="panel-title bold">Rekening Koran</h3>
        </div>
        <div class="panel-body p-5">
          <div class="row">
            <div class="col-10">
              <input
                v-model="filter.date"
                @change="fetchData"
                type="date"
                class="form-control mb-2"
              />
            </div>
            <div class="col-2">
              <button
                type="button"
                v-on:click="downloadExcel()"
                to="/data-transaksi/create"
                class="btn btn-success btn-block"
                :disabled="this.loadingDownload"
              >
                <i class="fa fa-file-excel d-md-none"></i>
                <i
                  class="fa fa-circle-notch fa-spin"
                  v-if="this.loadingDownload"
                ></i>
                <span class="d-none d-md-block" v-else>Export Excel</span>
              </button>
            </div>
          </div>
          <vue-good-table
            mode="remote"
            @on-page-change="onPageChange"
            @on-sort-change="onSortChange"
            @on-column-filter="onColumnFilter"
            @on-per-page-change="onPerPageChange"
            @on-search="onSearch"
            :totalRows="totalRecords"
            :isLoading.sync="isLoading"
            :search-options="{
              enabled: true,
              trigger: 'keyup',
              placeholder: 'Pencarian',
            }"
            :pagination-options="{
              enabled: true,
              perPageDropdownEnabled: true,
              perPageDropdown: [10, 20, 30, 40, 50, 100],
              dropdownAllowAll: false,
              position: 'bottom',
              rowsPerPageLabel: '',
              nextLabel: '',
              prevLabel: '',
              ofLabel: 'of',
              pageLabel: 'page',
              allLabel: '',
            }"
            :rows="rows"
            :columns="columns"
          >
            <div slot="emptystate" class="text-center">
              {{ tableInfo }}
            </div>
            <div slot="table-actions">
              <div style="display: flex">
                <button
                  type="button"
                  v-on:click="fetchData()"
                  class="btn btn-default mr-2"
                >
                  <i
                    class="fa fa-sync"
                    :class="{ 'fa-spin': this.isLoading }"
                  ></i>
                </button>
                <router-link
                  to="/master/rekening-koran/import"
                  class="btn btn-primary mr-2"
                >
                  <i class="fa fa-plus d-md-none"></i>
                  <span class="d-none d-md-block">Import Data</span>
                </router-link>
              </div>
            </div>
            <template slot="table-row" slot-scope="props">
              <span v-if="props.column.field == 'action'">
                <div>
                  <button
                    class="btn btn-danger btn-circle btn-icon"
                    v-on:click="confirmDelete(props.row.checking_account_id)"
                  >
                    <i class="fa fa-trash-alt"></i>
                  </button>
                </div>
              </span>
              <span v-else>
                {{ props.formattedRow[props.column.field] }}
              </span>
            </template>
          </vue-good-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import sipData from "../../../plugins/sipData";
const ExcelJS = require("exceljs");

export default {
  name: "DataList",
  mixins: [sipData],
  data() {
    return {
      columns: [
        {
          label: "Kode",
          field: "transaction_code",
          sortable: false,
          width: "150px",
          thClass: "text-center text-nowrap",
          tdClass: "text-center text-nowrap",
        },
        {
          label: "Posting Date",
          field: "posting_date",
          sortable: false,
          width: "200px",
          thClass: "text-center text-nowrap",
          tdClass: "text-center text-nowrap",
        },
        {
          label: "Remark",
          field: "remark",
          sortable: false,
          thClass: "text-center text-nowrap",
          tdClass: "text-wrap",
        },
        {
          label: "Credit",
          field: "credit",
          sortable: false,
          width: "150px",
          formatFn: this.currencyFormat,
          thClass: "text-center text-nowrap",
          tdClass: "text-right text-nowrap",
        },
        {
          label: "Record",
          field: "record",
          sortable: false,
          width: "150px",
          formatFn: this.currencyFormat,
          thClass: "text-center text-nowrap",
          tdClass: "text-right text-nowrap",
        },
        {
          label: "Aksi",
          field: "action",
          sortable: false,
          width: "100px",
          tdClass: "text-center text-inverse valign-middle text-nowrap",
          thClass: "text-center text-nowrap",
        },
      ],
      rows: null,
      tableInfo: "Loading data",
      isLoading: false,
      loadingDownload: false,
      form: {
        customer_category_id: "",
        year_period: "",
        category: "",
        category_name: "",
        cost: "",
      },
      modalVisible: false,
      conditionMet: false,
      formValidate: [],
      categoriesList: [],
      link: "/v1/customer_categories/create/",
      totalRecords: 0,
      serverParams: {
        columnFilters: {},
        sort: [
          {
            field: "",
            type: "",
          },
        ],
        page: 1,
        perPage: 10,
      },
      filter: {
        district: "",
        village: "",
        date: "",
      },
      searchTerm: "",
      timeoutSearch: null,
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    onSearch(params) {
      clearTimeout(this.timeoutSearch);
      this.timeoutSearch = setTimeout(() => {
        this.searchTerm = params.searchTerm;
        this.fetchData();
      }, 500);
    },
    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps);
    },

    onPageChange(params) {
      this.updateParams({ page: params.currentPage });
      this.fetchData();
    },

    onPerPageChange(params) {
      this.updateParams({ perPage: params.currentPerPage });
      this.fetchData();
    },

    onSortChange(params) {
      this.updateParams({
        sort: [
          {
            type: params.sortType,
            field: this.columns[params.columnIndex].field,
          },
        ],
      });
      this.fetchData();
    },

    onColumnFilter(params) {
      this.updateParams(params);
      this.fetchData();
    },
    fetchData() {
      this.isLoading = true;
      axios
        .get("/v1/checking_account/", {
          headers: {
            token: this.userToken,
          },

          params: {
            search: this.searchTerm,
            page: this.serverParams.page,
            limit: this.serverParams.perPage,
            date: this.filter.date,
          },
        })
        .then((response) => {
          this.rows = response.data.data;
          this.totalRecords = response.data.total;
          if (this.rows.length <= 0) {
            this.tableInfo = "Empty data";
          }
          this.isLoading = false;
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
    confirmDelete(id) {
      Swal.fire({
        title: "Apakah Anda Yakin ?",
        text: "Data Akan Terhapus Secara Permanen",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "Batal",
        confirmButtonText: "Hapus!",
      }).then((result) => {
        if (result.value) {
          this.deleteData(id);
        }
      });
    },
    deleteData(id) {
      try {
        axios
          .get("/v1/checking_account/delete/" + id)
          .then((response) => {
            if (response.status === 200) {
              Swal.fire({
                title: "Sukses",
                text: "Data Berhasil Dihapus",
                icon: "success",
                showCancelButton: false,
                confirmButtonColor: "#3085d6",
                confirmButtonText: "OK!",
              }).then(() => {
                this.fetchData();
              });
            }
          })
          .catch((error) => {
            console.log(error.message);
            this.error = error.message;
          });
      } catch (err) {
        console.error(err);
      }
    },
    currencyFormat(value) {
      if (!value) return "";
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    downloadExcel() {
      this.loadingDownload = true;
      axios
        .get("/v1/checking_account/export", {
          headers: {
            token: this.userToken,
          },
          params: {
            search: this.searchTerm,
            page: this.serverParams.page,
            limit: this.serverParams.perPage,
            date: this.filter.date,
          },
        })
        .then((response) => {
          let data = response.data.data;

          let dataArray = data.map((item, index) => ({
            index: index + 1,
            transaction_code: item.transaction_code || " ",
            posting_date: item.posting_date || " ",
            remark: item.remark || " ",
            credit: this.currencyFormat(item.credit) || " ",
            record: this.currencyFormat(item.record) || " ",
          }));

          const workbook = new ExcelJS.Workbook();
          const worksheet = workbook.addWorksheet("Rekening Koran");

          const columnWidths = [
            { header: "NO.", key: "index", width: 5 },
            { header: "KODE", key: "transaction_code", width: 25 },
            { header: "POSTING DATE", key: "posting_date", width: 20 },
            { header: "REMARK", key: "remark", width: 100 },
            { header: "CREADIT", key: "credit", width: 15 },
            {
              header: "RECORD",
              key: "record",
              width: 15,
            },
          ];

          worksheet.columns = columnWidths;

          dataArray.forEach((item) => {
            worksheet.addRow(item);
          });

          const columnCenter = ["posting_date", "transaction_code"];
          columnCenter.forEach((columnName) => {
            const column = worksheet.getColumn(columnName);
            column.alignment = {
              horizontal: "center",
              vertical: "middle",
              wrapText: true,
            };
          });

          const columnRight = ["index", "credit", "record"];
          columnRight.forEach((columnName) => {
            const column = worksheet.getColumn(columnName);
            column.alignment = {
              horizontal: "right",
              vertical: "middle",
              wrapText: true,
            };
          });

          const columnLeft = ["remark"];
          columnLeft.forEach((columnName) => {
            const column = worksheet.getColumn(columnName);
            column.alignment = {
              horizontal: "left",
              vertical: "middle",
              wrapText: true,
            };
          });

          const headerRow = worksheet.getRow(1);
          headerRow.eachCell((cell) => {
            cell.font = { bold: true };
            cell.alignment = {
              horizontal: "center",
              vertical: "middle",
              wrapText: true,
            };
          });

          const borderStyle = {
            style: "thin",
            color: { argb: "000000" },
          };

          worksheet.eachRow((row) => {
            row.eachCell((cell) => {
              cell.border = {
                top: borderStyle,
                left: borderStyle,
                bottom: borderStyle,
                right: borderStyle,
              };
            });
          });

          return workbook.xlsx.writeBuffer();
        })
        .then((excelBuffer) => {
          this.saveAsExcelFile(excelBuffer, "rekening-koran.xlsx");

          this.loadingDownload = false;
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
    saveAsExcelFile(buffer, filename) {
      const blob = new Blob([buffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      if (navigator.msSaveBlob) {
        navigator.msSaveBlob(blob, filename);
      } else {
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = filename;
        link.click();
      }
    },
  },
};
</script>